$white: #fff;
$h60: 60px;
$h16: 16px;

.container {
  display: flex;
  justify-content: space-between;
  min-height: $h60;
  background-color: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  padding-left: $h16;
  padding-right: $h16;

  .toggleMenu {
    cursor: pointer;
  }
  .rightAppBar {
    display: flex;
    align-items: center;
  }
}