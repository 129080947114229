.container {
  display: flex;
  align-items: center;
}
.menu {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .item {
    display: flex;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}