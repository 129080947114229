#root {
  height: 100%;
}

body {
  line-height: 1.25;
  overflow: hidden;
  font-family: Roboto;
}

.ui.form .field .ui.input input {
  border-radius: 2px !important;
}

.ui.form .field .errors {
  color: #9f3a38;
  font-size: 12px;
  font-family: Roboto;
  margin-top: 5px;
  font-style: italic;
}

.ui.pagination.menu .item {
  border-radius: 2px !important;
}

.ui.pagination.menu .active.item {
  border-color: #1258AF !important;
}
.ui.selection.dropdown {
  border-radius: 2px !important;
}