$white: #fff;
.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 200px;
  max-width: 200px;

  &.menuToggle {
    width: 70px;
    max-width: 70px;
  }

  .item {
    margin-top: 5px;
    margin-bottom: 5px;
    &.active {
      // background-color: #19355e;
      // .icon {
      //   opacity: 1;
      // }
      // .name {
      //   opacity: 1;
      // }
      .itemLi { 
        background-color: #19355e;
      }
    }

    &:hover {
      .itemLi { 
        background-color: #19355e;
      }
    }
    
    .logoName {
      margin-left: 10px;
      color: $white;
      font-size: 12px;
    }

    .itemLi {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 25px;
      padding-right: 10px;
      justify-content: space-between;

      .itemLeft {
        display: flex;
        align-items: center;
      }

      img {
        margin-top: -3px;
      }
    }

    .icon {
      width: 16px;
      height: 14px;
      margin-bottom: 5px;
    }

    .name {
      font-size: 10px;
      color: $white;
      margin-left: 10px;
      text-transform: uppercase;
    }

    .subMenu {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: $white;

      a {
        padding: 0;
        color: $white;
        line-height: 35px;
        padding-left: 60px;
      }
    }
  }
}

.ic_dashboard {
  background: url('./icons/ic_coolicon.svg');
  background-size: 100% 100%;
}
.ic_customer {
  background: url('./icons/ic_customer.svg');
  background-size: 100% 100%;
}
.ic_partner {
  background: url('./icons/ic_partner.svg');
  background-size: 100% 100%;
}
.ic_voucher {
  background: url('./icons/ic_voucher.svg');
  background-size: 100% 100%;
}
.ic_explore {
  background: url('./icons/ic_explore.svg');
  background-size: 100% 100%;
}
.ic_advertisement {
  background: url('./icons/ic_advertisement.svg');
  background-size: 100% 100%;
}
.ic_user {
  background: url('./icons/ic_user.svg');
  background-size: 100% 100%;
}
.ic_care {
  background: url('./icons/ic_care.svg');
  background-size: 100% 100%;
}