.container {
  display: flex;
  align-items: center;
  margin-right: 50px;

  .profile {
    display: flex;
    align-items: center;

    .information {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      span {
        font-size: 11px;
      }
      strong {
        font-size: 11px;
      }
    }
  }

  img {
    margin-left: 10px;
  }
}
.menu {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .item {
    display: flex;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}