.root {
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  flex: 1
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}

.body {
  flex-grow: 1;
  flex-shrink: 0;
  background-color: #f8f8f8;
  flex-basis: auto;
  position: relative;
  flex: 1;
}

.bsBody {
  display: flex;
  height: 100%;

  .bsLeftMenu {
    display: flex;
    flex-grow: 1;
    display: flex;
    z-index: 700;
    background-color: #1A213C;

    // &::before {
    //   bottom: 0;
    //   content: '';
    //   left: 0;
    //   position: fixed;
    //   top: 0;
    //   width: 180px;
    //   z-index: 699;
    //   display: flex;
    //   background-color: #1A213C;
    // }
  }
  .bsContent {
    position: relative;
    flex-grow: 999;
    display: flex;
    flex-direction: column;

    .bsContainer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      flex: 1;
      margin: 16px;
      padding: 20px;
    }

    .bsContainerR {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 16px;
      padding: 20px;
    }
  }
}